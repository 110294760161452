// https://tailwindcss.com/docs/configuration

const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/**/*.{php,vue,js,jsx}",
    "!./resources/blocks/*.js",
    "./config/acf.php"
  ],
  safelist: [':where(a)', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  theme: {
    colors: {
      blue: {
        'green': '#174A5B',
        'teal': '#259591',
        'ice': '#BCD5DA'
      },
      pink: {
        DEFAULT: '#DF4661',
        rose: '#D86C71'
      },
      green: {
        DEFAULT: '#C6D843',
      },
      orange: {
        DEFAULT: '#EB7722'
      },
      yellow: '#FDDA25',
      skin: {
        100: '#FFE8C7',
        200: '#F6D8CE',
        300: '#D6B292',
        400: '#D9A28B',
        500: '#9E7761',
        600: '#634426'
      },
      hair: {
        100: '#CAAC42',
        200: '#20353D',
        300: '#5A492F',
      },
      gray: {
        50: '#F6F6F6',
        100: '#D9D9D9'
      },
      white: '#FFFFFF',
      black: '#202020',
      transparent: 'transparent',
      current: 'currentColor',
      'set-bg': 'rgb(var(--bg) / <alpha-value>)',
      'set-text': 'rgb(var(--text) / <alpha-value>)',
    },
    fontFamily: {
      heading: ['Montserrat Variable', 'sans-serif'],
      body: ['"Open Sans Variable"', 'sans-serif'],
    },
    fontSize: ({ theme }) => ({
      '2xs': ['0.625rem', {
        lineHeight: '1.5'
      }],
      xs: ['0.75rem', {
        lineHeight: '1.5'
      }],
      sm: ['0.875rem', {
        lineHeight: '1.5'
      }],
      base: ['1rem', {
        lineHeight: '1.5'
      }],
      lg: ['1.125rem', {
        lineHeight: '1.6'
      }],
      xl: ['1.25rem', {
        lineHeight: '1.6'
      }],
      '2xl': ['1.5rem', {
        lineHeight: '1.5'
      }],
      '3xl': ['1.75rem', {
        lineHeight: '1.25',
        fontWeight: theme('fontWeight.bold')
      }],
      '4xl': ['2rem', {
        lineHeight: '1.5'
      }],
      '5xl': ['2.5rem', {
        lineHeight: '1.2',
        fontWeight: theme('fontWeight.bold')
      }],
      '6xl': ['3.5rem', {
        lineHeight: '1',
        fontWeight: theme('fontWeight.bold')
      }],
      '7xl': ['4rem', {
        lineHeight: '1',
        fontWeight: theme('fontWeight.bold')
      }]
    }),
    transform: 'translate(var(--tw-translate-x), var(--tw-translate-y), var(--tw-translate-x)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))',
    extend: {
      animation: {
        'flip-open': 'flip-open 300ms ease-in forwards',
        'flip-close': 'flip-close 300ms ease-in forwards',
        'flip-vert-open': 'flip-vert-open 300ms ease-in forwards',
        'flip-vert-close': 'flip-vert-close 300ms ease-in forwards',
        'reveal': 'reveal infinite ease-in forwards',
        'marquee': 'scrolling var(--marquee-time) linear infinite'
      },
      boxShadow: {
        'card-1': '0px 3.195002794265747px 50px 0px rgba(0, 0, 0, 0.06)',
        'card-2': '51.3394px 3.90547px 61.11834px 0px rgba(0, 0, 0, 0.06)'
      },
      content: {
        quote: '\\201C'
      },
      keyframes: {
        'flip-open': {
          'from': {
            transform: 'rotateY(0deg)'
          },
          'to': {
            transform: 'rotateY(180deg)'
          }
        },
        'flip-close': {
          '0%': {
            transform: 'rotateY(-180deg)'
          },
          '100%': {
            transform: 'rotateY(0deg)'
          }
        },
        'flip-vert-open': {
          'from': {
            transform: 'rotateX(0deg)'
          },
          'to': {
            transform: 'rotateX(180deg)'
          }
        },
        'flip-vert-close': {
          '0%': {
            transform: 'rotateX(-180deg)'
          },
          '100%': {
            transform: 'rotateX(0deg)'
          }
        },
        'reveal': {
          '6%, 100%': {
            'stroke-dashoffset': 0
          }
        },
        'reveal-tip': {
          '6%': {
            'stroke-dashoffset': 'var(--offset)'
          },
          '9%, 100%': {
            'stroke-dashoffset': 0
          }
        },
        scrolling: {
          '0%': {
            transform: 'translateX(0)'
          },
          '100%': {
            transform: 'translateX(calc(-1 * var(--marquee-width)))'
          }
        }
      },
      backgroundImage: {
        'rays': 'url(@/assets/images/rays.svg)',
        'sparks': 'url(@/assets/images/sparks.svg)',
        'blob-1': 'url(@/assets/images/blob-1.svg)',
        'blob-2': 'url(@/assets/images/blob-2.svg)',
        'logo': 'url(@/assets/images/logo.svg)',
        'chevron': 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'9\' height=\'5\' viewBox=\'0 0 9 5\' fill=\'none\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M8.86522 0.282985C8.82032 0.235031 8.76641 0.196394 8.70658 0.169283C8.64674 0.142172 8.58215 0.127118 8.51649 0.124982C8.45083 0.122847 8.38539 0.13367 8.32392 0.156834C8.26245 0.179998 8.20614 0.215051 8.15822 0.259985L4.50022 3.68998L0.842222 0.259985C0.745418 0.169281 0.616547 0.120746 0.483959 0.12506C0.35137 0.129373 0.225926 0.18618 0.135222 0.282984C0.0445179 0.379789 -0.00401649 0.508659 0.000296963 0.641247C0.00461042 0.773836 0.0614179 0.89928 0.158222 0.989984L4.15822 4.73998C4.2509 4.82688 4.37318 4.87524 4.50022 4.87524C4.62726 4.87524 4.74955 4.82688 4.84222 4.73998L8.84222 0.989985C8.89018 0.945086 8.92881 0.891178 8.95592 0.83134C8.98303 0.771503 8.99809 0.706909 9.00022 0.641251C9.00236 0.575593 8.99154 0.510158 8.96837 0.448685C8.94521 0.387212 8.91016 0.330906 8.86522 0.282985Z\' fill=\'black\'/%3E%3C/svg%3E")'
      },
      typography: require('./tw-typography.config.js')
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    plugin(require('./tw-forms.config.js')),
    plugin(function({ addVariant }) {
      addVariant('inner', [
        '& > .block-editor-inner-blocks > .block-editor-block-list__layout',
        '& > .block-editor-block-list__layout'
      ])
      addVariant('inner-block', [
        '& > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-editor-block-list__block',
        '& > .block-editor-block-list__layout > .block-editor-block-list__block'
      ])
      addVariant('appender', ['& > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-list-appender', '& .block-list-appender'])
      addVariant('appender-button', '& > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-list-appender > button')
      addVariant('menu', '& > .wp-block-navigation > ul')
      addVariant('menu-item', '& > .wp-block-navigation > ul > .wp-block-navigation-link')
      addVariant('menu-link', '& > .wp-block-navigation > ul > .wp-block-navigation-link > a')
    }),
    plugin(function({ matchUtilities, theme, addBase }) {
      addBase({
        ':root': {
          '--tw-image': ''
        }
      })

      matchUtilities(
        {
          'image': (value) => ({
            '--tw-image': value
          }),
        },
        { values: theme('backgroundImage') }
      )
    }),
    plugin(function({ matchUtilities, theme, addBase }) {
      addBase({
        ':root': {
          '--tw-translate-z': 0
        }
      })

      matchUtilities(
        {
          'translate-z': (value) => ({
            '--tw-translate-z': value,
            transform: ` translate3d(var(--tw-translate-x), var(--tw-translate-y), var(--tw-translate-z)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`,
          }),
        },
        { values: theme('translate'), supportsNegativeValues: true }
      ),
      matchUtilities(
        {
          'rotate-x': (value) => ({
            '--tw-rotate': value,
            transform: ` translate3d(var(--tw-translate-x), var(--tw-translate-y), var(--tw-translate-z)) rotateX(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))`,
          }),
        },
        { values: theme('rotate'), supportsNegativeValues: true }
      )
    }),
    plugin(function({ matchVariant }) {
      matchVariant(
        'crew',
        (value = '') => {
          return value.length > 0
            ? `:merge(.crew\\/${value}) &`
            : `:merge(.crew) &`
        },
      )
    }),
    plugin(function({ matchVariant, prefix }) {
      matchVariant('has', (value) => `&:has(${value})`, { values: {} })
    })
  ],
}
