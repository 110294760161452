import { default as header } from './_header'
import { nav as nav } from './_header'
import { navItem as navItem } from './_header'
import { 
  default as Quiz,
  Question as Question
} from './_quiz'
// import { default as ScrollingBlock } from './_scrolling-block'
import { default as EnterpriseLicensing } from './_enterprise-licensing'
import { default as PostsSlider } from './_posts-slider'
import { default as PostsList } from './_posts-list'
import { default as Reveal } from './_reveal'
import { default as Marquee } from './_marquee'

window.Alpine.store('header', header)

window.Alpine.data('Quiz', Quiz)
window.Alpine.data('Question', Question)
// window.Alpine.data('ScrollingBlock', ScrollingBlock)
window.Alpine.data('PostsSlider', PostsSlider)
window.Alpine.data('PostsList', PostsList)
window.Alpine.data('Reveal', Reveal)
window.Alpine.data('Marquee', Marquee)
window.Alpine.data('nav', nav)
window.Alpine.data('navItem', navItem)
window.Alpine.data('EnterpriseLicensing', EnterpriseLicensing)