export default (formId, userId) => ({
  current: null,

  responses: {},

  questions: {},

  userId: userId,

  form: formId,

  init() {
    const container = this.$refs.questions
    const containerWidth = container.offsetWidth

    this.$watch('current', value => {
      const card = container.children[value - 1]
      const cardWidth = card?.offsetWidth ?? 0
      const cardLeft = card?.getBoundingClientRect().left ?? 0
      const containerLeft = container.getBoundingClientRect().left

      const distanceFromLeft = cardLeft - containerLeft
      const centerPoint = containerWidth / 2
      const cardCenterPoint = cardWidth / 2

      container.style.transform = `translateX(${centerPoint - (distanceFromLeft + cardCenterPoint)}px)`
    })

    this.current = 1
  },

  submit(callback) {
    const headers = new Headers()

    headers.append('X-WP-Nonce', wpApiSettings.nonce)
    headers.append('Content-Type', 'application/json')

    fetch('/wp-json/crew/v1/quiz-entry', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        id: this.userId,
        formId: this.form,
        url: window.location.href,
        responses: this.responses,
        questions: this.questions
      })
    }).then(response =>  response.json())

    callback()
  },

  get isEnd() {
    return (this.$refs.questions.children.length - 1) === this.current
  }
})

export const Question = (correctAnswer, question) => ({
  id: null,

  answer: correctAnswer,

  question: question,

  init() {
    this.id = [...this.$el.parentNode.children].indexOf(this.$el) + 1

    this.$nextTick(() => {
      this.questions[this.id] = this.question
    })

    this.$watch('active', value => {
      if (this.id < this.current) {
        this.$refs.card.style.transformOrigin = 'bottom right'
      }

      if (this.id > this.current) {
        this.$refs.card.style.transformOrigin = 'bottom left'
      }
    })

    this.$nextTick(() => {
      if (this.id > this.current) {
        this.$refs.card.style.transformOrigin = 'bottom left'
      }
    })
  },
  
  get active() {
      return this.current === this.id
  },

  get correct() {
    return this.answer === this.response
  },

  get response() {
    if (this.id === null) return null
    
    if (typeof this.responses[this.id] === 'undefined') {
      return null
    }

    return this.responses[this.id]
  },

  set response(value) {
    this.responses[this.id] = value
  },

  scenario: {
    ['x-bind:class']() {
      return {
        'transition duration-300': true,
        'opacity-0 invisible': this.response !== null
      }
    }
  },

  explanation: {
    ['x-bind:class']() {
      return {
        'transition delay-300': true,
        'opacity-0 invisible': this.response === null
      }
    }
  }
})
