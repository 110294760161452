import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

const tw = resolveConfig(tailwindConfig)
const breakpoint = 'lg'

export default {
  el: null,

  toggleable: false,

  stuck: false,

  open: false,

  init() {
    this.el = document.getElementById('site-header')

    this.setNavStatus()
  },

  toggleNav() {
    this.open = ! this.open
  },

  setNavStatus() {
      this.open = window.matchMedia('(min-width: ' + tw.theme.screens[breakpoint] + ')').matches

      this.toggleable = ! window.matchMedia('(min-width: ' + tw.theme.screens[breakpoint] + ')').matches
  },

  get shrink () {
    return this.stuck && (! this.toggleable || ! this.open)
  }
}

export function nav() {
  return ({
    active: false
  })
}

export function navItem(id) {
  return ({
    id: id,

    closing: false,

    get open() {
      return this.id == this.active
    },

    set open (value) {
      this.active = value ? this.id : false
    },

    el: {
      ['@mouseover']() {
        if (this.$store.header.toggleable) {
          return
        }

        this.open = true
        this.closing = false
      },

      ['@mousemove.throttle.250ms']() {
        if (this.$store.header.toggleable) {
          return
        }

        this.open = true
        this.closing = false
      },

      ['@mouseleave']() {
        if (this.$store.header.toggleable) {
          return
        }

        this.closing = true

        setTimeout(() => {
          if (this.closing) {
            this.open = false
          }
        }, 500)
      }
    },

    menu: {
      ['x-init']() {
        return () => {
          const overview = document.createElement('li')
          const overviewLink = document.createElement('a')

          overview.classList.add(`${breakpoint}:hidden`, 'relative', 'group/item', 'lg:text-transparent', 'py-2')
          overviewLink.classList.add('before:content-none', 'lg:before:content-[attr(data-text)]', 'before:absolute', 'before:top-1/2', 'before:left-1/2', 'before:-translate-x-1/2', 'before:-translate-y-1/2', 'before:text-blue-green', 'before:text-center', 'before:whitespace-nowrap', 'before:transition-all', 'relative', 'hover:before:font-bold', 'hover:before:text-blue-teal', 'font-medium', 'text-sm')
          overviewLink.href = this.$el.previousElementSibling.href
          overviewLink.setAttribute('data-text', 'Overview')
          overviewLink.text = 'Overview'

          overview.appendChild(overviewLink)
          this.$el.prepend(overview)
        }
      },

      ['x-show']() {
        return this.open
      },

      ['x-collapse']() {
        return true
      },

      ['@mouseover']() {
        this.open = true
        this.closing = false
      },

      ['@mousemove.throttle.250ms']() {
        this.open = true
        this.closing = false
      },
    },

    trigger: {
      ['@click'](event) {
        if (! this.$store.header.toggleable) {
          return
        }

        event.preventDefault()

        this.open = ! this.open
      },
    }
  })
}