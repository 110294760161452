export default () => ({
  ready: false,

  start: false,

  init() {
    const paths = this.$el.querySelectorAll('path')

    paths.forEach(path => {
      const length = path.getTotalLength()
  
      path.style.setProperty('stroke-dasharray', length + 'px')
      path.style.setProperty('stroke-dashoffset',length + 'px')
      path.style.setProperty('--offset',length + 'px')
    })

    this.ready = true

    this.$nextTick(() => {
      this.reveal()
    })
  },

  reveal () {
    if (! this.ready || ! this.start) {
      return
    }

    this.$refs.line?.classList.add(['animate-[reveal_var(--duration)_1_ease-in_forwards]'])
    this.$refs.tip?.classList.add(['animate-[reveal-tip_var(--duration)_1_ease-in_forwards]'])
  }
})