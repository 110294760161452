import { Splide } from '@splidejs/splide'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

const tw = resolveConfig(tailwindConfig)

export default () => ({
  posts: {
    ['x-init']() {
      const md = parseInt(tw.theme.screens.md.replace('px', ''))
      const xl = parseInt(tw.theme.screens.xl.replace('px', ''))

      new Splide(this.$el, {
        type: 'slide',
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        mediaQuery: 'min',
        breakpoints: {
          [md]: {
            perPage: 2,
            perMove: 1,
            gap: '1.5rem',
          },
          [xl]: {
            perPage: 3,
            perMove: 1,
            gap: '1.5rem',
          }
        }
      }).mount()
    }
  }
})