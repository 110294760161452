import { gsap } from 'gsap'

export default (config = {}) => ({
  items: [],

  config: {
    repeat: -1,
    speed: 1
  },

  pause: false,

  init() {
    this.items = gsap.utils.toArray('li', this.$refs.items)
    this.config = {
      ...this.config,
      config
    }

    const marquee = this.start()

    this.$watch('pause', value => {
      value ? marquee.pause() : marquee.play()
    })
  },

  start() {
    const tl = gsap.timeline({
      repeat: this.config.repeat,
      paused: this.config.paused,
      defaults: {
        ease: 'none'
      },
      onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
    })

    const length = this.items.length
    const startX = this.items[0].offsetLeft
    const widths = []
    const xPercents = []
    const pixelsPerSecond = (this.config.speed || 1) * 100
    const snap = this.config.snap === false ? v => v : gsap.utils.snap(this.config.snap || 1)

    gsap.set(this.items, {
      xPercent: (i, el) => {
        widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px'))
        xPercents[i] = snap(parseFloat(gsap.getProperty(el, 'x', 'px')) / widths[i] * 100 + gsap.getProperty(el, 'xPercent'))

        return xPercents[i]
      }
    })

    gsap.set(this.items, { x: 0 })

    const totalWidth = this.items[length - 1].offsetLeft 
      + xPercents[length - 1] / 100 
      * widths[length - 1] 
      - startX 
      + this.items[length - 1].offsetWidth 
      * gsap.getProperty(this.items[length - 1], 'scaleX') 
      + (parseFloat(config.paddingRight) || 0)

    this.items.forEach((item, index) => {
      const curX = xPercents[index] / 100 * widths[index]
      const distanceToStart = item.offsetLeft + curX - startX
      const distanceToLoop = distanceToStart + widths[index] * gsap.getProperty(item, 'scaleX')

      tl.to(item, {
        xPercent: snap((curX - distanceToLoop) / widths[index] * 100),
        duration: distanceToLoop / pixelsPerSecond
      }, 0)
      .fromTo(item, {
        xPercent: snap((curX - distanceToLoop + totalWidth) / widths[index] * 100)
      }, {
        xPercent: xPercents[index],
        duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
        immediateRender: false
      }, distanceToLoop / pixelsPerSecond)
    })

    tl.progress(1, true).progress(0, true)
    
    return tl
  }
})
