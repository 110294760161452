export default () => ({
  page: 1,
  
  valid: false,

  id: null,

  async init () {
    const form = this.$el.querySelector(':scope form')
    const pages = form.querySelectorAll(':scope .gform_page')

    this.id = form.getAttribute('data-formid')

    // await pages.forEach(async (page) => {
    //   const _page = page.cloneNode()
    //   const inner = page.querySelector(':scope .gform_page_fields')
    //   const innerWrapper = page.querySelector(':scope .gform_fields')
    //   const footer = page.querySelector(':scope .gform_page_footer')
    //   const fields = page.querySelectorAll(':scope .gfield')
    //   const fieldsWrapper = document.createElement('div')
    //   const fieldsWrapperInner = document.createElement('div')
      
    //   fieldsWrapper.classList.add('gform_fields_wrapper')
    //   fieldsWrapperInner.classList.add('gform_fields')

    //   fieldsWrapper.appendChild(fieldsWrapperInner)

    //   await fields.forEach(field => {
    //     if (field.classList.contains('gfield--type-section')) {
    //       innerWrapper.replaceChildren(field)
    //     } else {
    //       const input = field.querySelector(':scope input')

    //       fieldsWrapperInner.appendChild(field)
    //     }
    //   })

    //   innerWrapper.appendChild(fieldsWrapper)
    //   innerWrapper.appendChild(footer)

    //   _page.replaceChildren(inner)

    //   Alpine.morph(page, _page)
    // })

    window.jQuery(document).on('gform_page_loaded', (event, form_id, current_page) => {
      if (current_page == 4) {
        const requiredFields = this.$el.querySelectorAll(':scope .gform_page:last-child input:not([type="hidden"]):not([type="submit"])')    

        requiredFields.forEach(field => {
          field.addEventListener('input', event => {
            this.validate(requiredFields)
          })
        })
      }
    })

    this.$watch('page', (value, oldValue) => {
      const formId = form.getAttribute('data-formid')
      const newPageInput = document.getElementById(`gform_target_page_number_${formId}`)
      const oldPageInput = document.getElementById(`gform_source_page_number_${formId}`)
      const nextButton = document.querySelector(`#gform_page_${formId}_${oldValue} .gform_next_button`)
      const prevButton = document.querySelector(`#gform_page_${formId}_${oldValue} .gform_previous_button`)

      if (value > oldValue) {
        nextButton.click()
      } else {
        prevButton.click()
      }

      // this.enter(pages[value - 1])
      // this.exit(pages[oldValue - 1])

      // newPageInput.value = value + 1
      // oldPageInput.value = value
    })

    // pages.forEach((page, index) => {
    //   page.style.removeProperty('display')

    //   if ((index + 1) == this.page) {
    //       const inputWrappers = page.querySelectorAll(':scope .gform_fields_wrapper .gfield')

    //       const callback = (mutationList, observer) => {
    //         for (const mutation of mutationList) {
    //           if (mutation.attributeName === 'data-conditional-logic') {
    //             const visibility = mutation.target.getAttribute('data-conditional-logic')
    //             const inputs = mutation.target.querySelectorAll('input')

    //             inputs.forEach(input => {
    //               input.disabled = visibility == 'hidden'
    //             })
    //           }
    //         }
    //       }

    //       const observer = new MutationObserver(callback)

    //       inputWrappers.forEach(wrapper => {
    //           observer.observe(wrapper, { attributes: true })
    //       })

    //       page.classList.add('is-active')
    //   }
    // })

    const fields = form.querySelectorAll(':scope .gfield')

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.attributeName === 'data-conditional-logic') {
          const visibility = mutation.target.getAttribute('data-conditional-logic')
          const inputs = mutation.target.querySelectorAll(':scope input')

          inputs.forEach(input => {
            input.disabled = false
          })
        }
      }
    }
  
    const observer = new MutationObserver(callback)

    fields.forEach(wrapper => {
        observer.observe(wrapper, { attributes: true })
    })
  },

  async validate(fields) {
    let valid = true
    
    await fields.forEach(field => {
      if (field.type == 'text' && field.value.length < 1) {
        valid = false
      }

      if (field.type == 'checkbox' && ! field.checked) {
        valid = false
      }
    })

    this.valid = valid
  },

  enter (el) {
    const anchor = this.$el.querySelector(':scope .gform_anchor')
    // const inputs = el.querySelectorAll(':scope .gform_fields_wrapper input')
    
    // inputs.forEach(input => {
    //   input.disabled = false
    // })

    anchor.scrollIntoView({
      behavior: 'smooth'
    })
    
    // el.classList.add('is-active')
  },

  exit (el) {
    const inputs = el.querySelectorAll(':scope .gform_fields_wrapper input')
    
    inputs.forEach(input => {
      input.disabled = true
    })

    el.classList.remove('is-active')
  },
  
  submit () {
    const form = this.$root.querySelector(':scope form')
    // const submit = this.$root.querySelector(':scope input[type="submit"]:not(.gform_previous_button, .gform_next_button)')

    form.submit()
  }
})