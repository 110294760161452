module.exports = ({ theme }) => {
  const round = (num) => num.toFixed(7)
      .replace(/(\.[0-9]+?)0+$/, '$1')
      .replace(/\.0$/, '')
  const rem = (px) => `${round(px / 16)}rem`
  const em = (px, base) => `${round(px / base)}em`

  const DEFAULT = {
    css: {
      maxWidth: 'none',
      fontSize: theme('fontSize.xl')[0],
      '--wp--preset--font-size--6-xl': theme('fontSize.6xl')[0],
      '--wp--preset--font-size--5-xl': theme('fontSize.5xl')[0],
      '--wp--preset--font-size--4-xl': theme('fontSize.4xl')[0],
      '--wp--preset--font-size--3-xl': theme('fontSize.3xl')[0],
      '--wp--preset--font-size--2-xl': theme('fontSize.2xl')[0],
      'h1': {
        '--tw-prose-size': theme('fontSize.5xl')[0],
        '--tw-prose-lineheight': theme('fontSize.5xl')[1].lineHeight,
        fontSize: 'var(--tw-prose-size)',
        lineHeight: 'var(--tw-prose-lineheight)',
        fontWeight: 700,
        fontFamily: theme('fontFamily.heading').join(', ')
      },
      'h2': {
        '--tw-prose-size': theme('fontSize.4xl')[0],
        '--tw-prose-lineheight': theme('fontSize.4xl')[1].lineHeight,
        fontSize: 'var(--tw-prose-size)',
        lineHeight: 'var(--tw-prose-lineheight)',
        fontFamily: theme('fontFamily.heading').join(', ')
      },
      'h3': {
        '--tw-prose-size': theme('fontSize.3xl')[0],
        '--tw-prose-lineheight': theme('fontSize.3xl')[1].lineHeight,
        fontSize: 'var(--tw-prose-size)',
        lineHeight: 'var(--tw-prose-lineheight)',
        fontFamily: theme('fontFamily.heading').join(', ')
      },
      'h4': {
        '--tw-prose-size': theme('fontSize.2xl')[0],
        '--tw-prose-lineheight': theme('fontSize.2xl')[1].lineHeight,
        fontSize: 'var(--tw-prose-size)',
        lineHeight: 'var(--tw-prose-lineheight)',
        fontFamily: theme('fontFamily.heading').join(', ')
      },
      p: {
        ...theme('fontSize.xl')[1],
        '--tw-prose-size': theme('fontSize.xl')[0],
        '--tw-prose-lineheight': theme('fontSize.xl')[1].lineHeight,
        fontSize: 'var(--tw-prose-size) !important',
        lineHeight: 'var(--tw-prose-lineheight)',
      },
      '.has-text-align-center': {
        textAlign: 'center'
      },
      '.has-7-xl-font-size': {
        '--tw-prose-size': theme('fontSize.7xl')[0],
        '--tw-prose-lineheight': theme('fontSize.7xl')[1].lineHeight
      },
      '.has-6-xl-font-size': {
        '--tw-prose-size': theme('fontSize.6xl')[0],
        '--tw-prose-lineheight': theme('fontSize.6xl')[1].lineHeight
      },
      '.has-5-xl-font-size': {
        '--tw-prose-size': theme('fontSize.5xl')[0],
        '--tw-prose-lineheight': theme('fontSize.5xl')[1].lineHeight
      },
      '.has-4-xl-font-size': {
        '--tw-prose-size': theme('fontSize.4xl')[0],
        '--tw-prose-lineheight': theme('fontSize.4xl')[1].lineHeight
      },
      '.has-3-xl-font-size': {
        '--tw-prose-size': theme('fontSize.3xl')[0],
        '--tw-prose-lineheight': theme('fontSize.3xl')[1].lineHeight
      },
      '.has-2-xl-font-size': {
        '--tw-prose-size': theme('fontSize.2xl')[0],
        '--tw-prose-lineheight': theme('fontSize.2xl')[1].lineHeight
      },
      '.has-xl-font-size': {
        '--tw-prose-size': theme('fontSize.xl')[0],
        '--tw-prose-lineheight': theme('fontSize.xl')[1].lineHeight
      },
      '.has-lg-font-size': {
        '--tw-prose-size': theme('fontSize.lg')[0],
        '--tw-prose-lineheight': theme('fontSize.lg')[1].lineHeight
      },
      '.has-base-font-size': {
        '--tw-prose-size': theme('fontSize.base')[0],
        '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
      },
      '.has-sm-font-size': {
        '--tw-prose-size': theme('fontSize.sm')[0],
        '--tw-prose-lineheight': theme('fontSize.sm')[1].lineHeight
      },
      '.has-xs-font-size': {
        '--tw-prose-size': theme('fontSize.xs')[0],
        '--tw-prose-lineheight': theme('fontSize.xs')[1].lineHeight
      },
      '.has-2-xs-font-size': {
        '--tw-prose-size': theme('fontSize.2xs')[0],
        '--tw-prose-lineheight': theme('fontSize.2xs')[1].lineHeight
      },
      'strong': {
        fontWeight: 700
      }
    },
  }

  return {
    DEFAULT: {
      ...DEFAULT,
      '--tw-prose-body': theme('colors.blue[green]'),
      '--tw-prose-headings': theme('colors.blue[green]'),
      '--tw-prose-lead': theme('colors.blue[green]'),
      '--tw-prose-links': theme('colors.blue[green]'),
      '--tw-prose-bold': theme('colors.blue[green]'),
      '--tw-prose-counters': theme('colors.blue[green]'),
      '--tw-prose-bullets': theme('colors.blue[green]'),
      '--tw-prose-hr': theme('colors.blue[green]'),
      '--tw-prose-quotes': theme('colors.blue[green]'),
      '--tw-prose-quote-borders': theme('colors.blue[green]'),
      '--tw-prose-captions': theme('colors.blue[icy]'),
      '--tw-prose-code': theme('colors.blue[green]'),
      '--tw-prose-pre-code': theme('colors.blue[green]'),
      '--tw-prose-pre-bg': theme('colors.blue[green]'),
      '--tw-prose-th-borders': theme('colors.blue[green]'),
      '--tw-prose-td-borders': theme('colors.blue[green]'),
      '--tw-prose-invert-body': theme('colors.white'),
      '--tw-prose-invert-headings': theme('colors.white'),
      '--tw-prose-invert-lead': theme('colors.white'),
      '--tw-prose-invert-links': theme('colors.white'),
      '--tw-prose-invert-bold': theme('colors.white'),
      '--tw-prose-invert-counters': theme('colors.white'),
      '--tw-prose-invert-bullets': theme('colors.white'),
      '--tw-prose-invert-hr': theme('colors.blue[icy]'),
      '--tw-prose-invert-quotes': theme('colors.blue[icy]'),
      '--tw-prose-invert-quote-borders': theme('colors.blue[icy]'),
      '--tw-prose-invert-captions': theme('colors.white'),
      '--tw-prose-invert-code': theme('colors.white'),
      '--tw-prose-invert-pre-code': theme('colors.white'),
      '--tw-prose-invert-pre-bg': 'rgb(0 0 0 / 50%)',
      '--tw-prose-invert-th-borders': theme('colors.white'),
      '--tw-prose-invert-td-borders': theme('colors.blue[icy]')
    },
    base: {
      ...DEFAULT
    },
    current: {
      css: {
        '--tw-prose-body': 'currentColor',
        '--tw-prose-headings': 'currentColor',
        '--tw-prose-lead': 'currentColor',
        '--tw-prose-links': 'currentColor',
        '--tw-prose-bold': 'currentColor',
        '--tw-prose-counters': 'currentColor',
        '--tw-prose-bullets': 'currentColor',
        '--tw-prose-hr': 'currentColor',
        '--tw-prose-quotes': 'currentColor',
        '--tw-prose-quote-borders': 'currentColor',
        '--tw-prose-captions': 'currentColor',
        '--tw-prose-code': 'currentColor',
        '--tw-prose-pre-code': 'currentColor',
        '--tw-prose-pre-bg': 'currentColor',
        '--tw-prose-th-borders': 'currentColor',
        '--tw-prose-td-borders': 'currentColor'
      },
    },
    sm: {
      css: {
        fontSize: theme('fontSize.base')[0],
        '--wp--preset--font-size--6-xl': theme('fontSize.4xl')[0],
        '--wp--preset--font-size--5-xl': theme('fontSize.3xl')[0],
        '--wp--preset--font-size--4-xl': theme('fontSize.2xl')[0],
        '--wp--preset--font-size--3-xl': theme('fontSize.xl')[0],
        '--wp--preset--font-size--2-xl': theme('fontSize.lg')[0],
        h1: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.3xl')[0],
          '--tw-prose-lineheight': theme('fontSize.3xl')[1].lineHeight,
          fontWeight: 700
        },
        h2: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.2xl')[0],
          '--tw-prose-lineheight': theme('fontSize.2xl')[1].lineHeight
        },
        h3: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.xl')[0],
          '--tw-prose-lineheight': theme('fontSize.xl')[1].lineHeight
        },
        h4: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.lg')[0],
          '--tw-prose-lineheight': theme('fontSize.lg')[1].lineHeight
        },
        p: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
        '.has-7-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.7xl')[0],
          '--tw-prose-lineheight': theme('fontSize.7xl')[1].lineHeight
        },
        '.has-6-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.6xl')[0],
          '--tw-prose-lineheight': theme('fontSize.6xl')[1].lineHeight
        },
        '.has-5-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.5xl')[0],
          '--tw-prose-lineheight': theme('fontSize.5xl')[1].lineHeight
        },
        '.has-4-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.lg')[0],
          '--tw-prose-lineheight': theme('fontSize.lg')[1].lineHeight
        },
        '.has-3-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
        '.has-2-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
        '.has-xl-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
        '.has-lg-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
        '.has-base-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
        '.has-sm-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.sm')[0],
          '--tw-prose-lineheight': theme('fontSize.sm')[1].lineHeight
        },
        '.has-xs-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.xs')[0],
          '--tw-prose-lineheight': theme('fontSize.xs')[1].lineHeight
        },
        '.has-2-xs-font-size': {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.2xs')[0],
          '--tw-prose-lineheight': theme('fontSize.2xs')[1].lineHeight
        },
      }
    },
    xs: {
      css: {
        '--wp--preset--font-size--6-xl': theme('fontSize.3xl')[0],
        '--wp--preset--font-size--5-xl': theme('fontSize.2xl')[0],
        '--wp--preset--font-size--4-xl': theme('fontSize.2xl')[0],
        '--wp--preset--font-size--3-xl': theme('fontSize.xl')[0],
        '--wp--preset--font-size--2-xl': theme('fontSize.lg')[0],
        h1: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.xl')[0],
          '--tw-prose-lineheight': theme('fontSize.3xl')[1].lineHeight,
          fontWeight: 700
        },
        h2: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.lg')[0],
          '--tw-prose-lineheight': theme('fontSize.2xl')[1].lineHeight
        },
        h3: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.xl')[1].lineHeight
        },
        h4: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.base')[0],
          '--tw-prose-lineheight': theme('fontSize.lg')[1].lineHeight
        },
        p: {
          fontSize: 'var(--tw-prose-size)',
          '--tw-prose-size': theme('fontSize.sm')[0],
          '--tw-prose-lineheight': theme('fontSize.base')[1].lineHeight
        },
      }
    }
  }
}