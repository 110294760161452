import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

const tw = resolveConfig(tailwindConfig)

export default (categories = []) => ({
  page: 1,

  numposts: 8,

  categories: categories,

  category: null,

  catNavStuck: false,

  init() {
    this.numposts = this.setNumPosts()

    this.$nextTick(() => {
      const params = new URLSearchParams(window.location.search)

      if (params.has('category')) {
        const catObj = this.categories?.find(cat => cat.slug === params.get('category'))
    
        if (catObj) {
          this.category = catObj.id
        }
      }
    })


    this.$watch('page', page => {
      this.$refs.posts.scrollIntoView({behavior: 'smooth'})

      this.getPosts()
    })

    this.$watch('category', category => {
      this.page = 1
      this.$refs.header.scrollIntoView({behavior: 'smooth'})

      this.getPosts()
    })

    this.getPosts()
  },
  
  getPosts() {
    const container = this.$refs.posts.querySelector(':scope > div')

    this.$refs.posts.style.setProperty('opacity', 0.5)

    setTimeout(() => {
      this.fetchPosts()
        .then(html => {
          window.Alpine.morph(container, html)
        }).then(() => {
          this.$refs.posts.style.setProperty('opacity', 1)
      })
    }, 500)
  },

  async fetchPosts() {
    const params = new URLSearchParams({
      paged: this.page,
      numposts: this.numposts
    })

    if (this.category) {
      params.set('category', this.category)
    }

    const response = await fetch(`/wp-json/crew/v1/posts?${params}`, {
      method: 'GET',
      credentials: 'include'
    })

    return response.json()
  },

  get description () {
    if (this.category === null) {
      return false
    }

    const catObj = this.categories.find(cat => {
      return cat.id === this.category
    })

    return catObj?.description ?? ''
  },

  get title () {
    if (this.category === null) {
      return false
    }

    const catObj = this.categories.find(cat => {
      return cat.id === this.category
    })

    return catObj?.name ?? ''
  },

  setNumPosts() {
    if (window.matchMedia('(min-width: ' + tw.theme.screens.xl + ')').matches) {
      return 8
    }

    if (window.matchMedia('(min-width: ' + tw.theme.screens.sm + ')').matches) {
      return 6
    }

    return 3
  }
})